var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"loading":_vm.loadingCard}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.title)+" "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"loading":_vm.loading,"loading-text":"Loading... Please wait","headers":_vm.columns,"items":_vm.data,"search":_vm.search,"dense":""},scopedSlots:_vm._u([{key:"item.do_no_",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.$refs.refDeliveryDetail.getData(item.do_no_, 'delivery-preparation')}}},[_vm._v(_vm._s(item.do_no_))])]}},{key:"item.loading_schedule",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.appDateFormat(item.loading_schedule)))]}},{key:"item.po_no_",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"d-inline-block text-truncate",staticStyle:{"max-width":"250px"}},'span',attrs,false),on),[_vm._v(" "+_vm._s(item.po_no_)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.po_no_))])])]}},{key:"item.contract_no_",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"d-inline-block text-truncate",staticStyle:{"max-width":"250px"}},'span',attrs,false),on),[_vm._v(" "+_vm._s(item.contract_no_)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.contract_no_))])])]}},{key:"item.customer_name",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"d-inline-block text-truncate",staticStyle:{"max-width":"250px"}},'span',attrs,false),on),[_vm._v(" "+_vm._s(item.customer_name)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.customer_name))])])]}},{key:"item.campaign_description",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"d-inline-block text-truncate",staticStyle:{"max-width":"250px"}},'span',attrs,false),on),[_vm._v(" "+_vm._s(item.campaign_description)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.campaign_description))])])]}},{key:"item.line_order_amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.numberFormat(item.line_order_amount, 2))+" ")]}}])})],1),_c('DeliveryDetail',{ref:"refDeliveryDetail"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }