<template>
  <div>
    <v-card :loading="loadingCard">
      <!-- <v-skeleton-loader
        v-bind="{
          boilerplate: true,
          elevation: 2,
        }"
        v-if="loadingCard"
        type="tab, table"
      >
      </v-skeleton-loader> -->
      <v-card-title>
        {{ title }}
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :loading="loading"
        loading-text="Loading... Please wait"
        :headers="columns"
        :items="data"
        :search="search"
        dense
      >
        <template v-slot:item.do_no_="{ item }">
          <a href="javascript:;" @click="$refs.refDeliveryDetail.getData(item.do_no_, 'delivery-preparation')">{{
            item.do_no_
          }}</a>
        </template>
        <template v-slot:item.loading_schedule="{ item }">{{ appDateFormat(item.loading_schedule) }}</template>
        <template v-slot:item.po_no_="{ item }" class="text-truncate">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on" class="d-inline-block text-truncate" style="max-width: 250px">
                {{ item.po_no_ }}
              </span>
            </template>
            <span>{{ item.po_no_ }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.contract_no_="{ item }" class="text-truncate">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on" class="d-inline-block text-truncate" style="max-width: 250px">
                {{ item.contract_no_ }}
              </span>
            </template>
            <span>{{ item.contract_no_ }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.customer_name="{ item }" class="text-truncate">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on" class="d-inline-block text-truncate" style="max-width: 250px">
                {{ item.customer_name }}
              </span>
            </template>
            <span>{{ item.customer_name }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.campaign_description="{ item }" class="text-truncate">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on" class="d-inline-block text-truncate" style="max-width: 250px">
                {{ item.campaign_description }}
              </span>
            </template>
            <span>{{ item.campaign_description }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.line_order_amount="{ item }">
          {{ numberFormat(item.line_order_amount, 2) }}
        </template>
      </v-data-table>
    </v-card>

    <DeliveryDetail ref="refDeliveryDetail"></DeliveryDetail>
  </div>
</template>


<script>
// eslint-disable-next-line no-unused-vars
import { mdiDolly, mdiViewList } from '@mdi/js'
import DeliveryDetail from '@/components/delivery/DeliveryDetail.vue'

export default {
  components: {
    DeliveryDetail,
  },
  data: () => ({
    title: 'Delivery Preparation',
    icon: mdiDolly,
    search: null,
    loading: null,
    columns: [
      { text: 'Order Type', value: 'order_type' },
      { text: 'DO No.', value: 'do_no_' },
      { text: 'Loading Schedule', value: 'loading_schedule' },
      { text: 'Customer', value: 'customer_name' },
      { text: 'Project', value: 'campaign_description' },
      { text: 'Contract No.', value: 'contract_no_' },
      { text: 'PO No.', value: 'po_no_' },
    ],
    data: [],
    selectedData: {},
    loadingCard: null,
  }),
  methods: {
    async getData() {
      try {
        this.loadingCard = true
        this.loading = true
        this.data = []
        await this.$axios
          .get('delivery/delivery-preparation/list', {
            cacheConfig: true,
          })
          .then(res => {
            if (res.status) {
              var resData = res.data
              if (resData.status == 'success') {
                this.data = resData.data.results
              } else {
                this.showAlert(resData.status, resData.message)
              }
            }
            this.loading = false
            this.loadingCard = false
          })
          .catch(error => {
            this.showAlert('error', 'Internal Server Error : ' + error)
            this.loading = false
            this.loadingCard = false
          })
      } catch (error) {
        this.data = []
        this.loading = false
        this.loadingCard = false
        this.showAlert('error', 'Internal server error : ' + error)
      }
    },
  },

  beforeMount() {},

  mounted() {
    this.getData()
  },
}
</script>

<style>
table.font-size-sm tbody td {
  font-size: 12px !important;
}
.lbl {
  color: #bdbdbd !important;
}
.vl {
  color: #616161 !important;
}
</style>