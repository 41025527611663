<template>
  <div>
    <v-row>
      <v-col cols="12" md="6" lg="8" class="d-flex align-stretch" fluid>
        <v-hover>
          <template v-slot:default="{ hover }">
            <v-card dark height="100%" elevation="2" class="d-flex align-stretch" tile>
              <v-img :src="require('@/assets/images/bg1.jpg')">
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                  </v-row>
                </template>
                <v-card-text class="py-2 text-center">
                  <v-icon large color="primary">mdi-truck-fast</v-icon>
                  <h1>Delivery</h1>
                  <p>This page show delivery progress.</p>
                </v-card-text>
              </v-img>
              <v-fade-transition>
                <v-overlay v-if="hover" absolute color="#036358">
                  <a href="#delivery_progress" color="white">See more</a>
                </v-overlay>
              </v-fade-transition>
            </v-card>
          </template>
        </v-hover>
      </v-col>
      <v-col cols="12" md="2" lg="2">
        <StatisticsCardVertical
          :loading="loadingStatistic"
          change=""
          color="success"
          icon="mdi-cube"
          :statistics="numberFormat(totalCampaign.active).toString()"
          stat-title="Active Projects"
          subtitle="Active Projects"
          class="fill-height"
        ></StatisticsCardVertical>
      </v-col>
      <v-col cols="12" md="2" lg="2">
        <StatisticsCardVertical
          :loading="loadingStatistic"
          change=""
          color="primary"
          icon="mdi-cube"
          :statistics="numberFormat(totalCampaign.all).toString()"
          stat-title="All Projects"
          subtitle="All Projects"
          class="fill-height"
        ></StatisticsCardVertical>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <your-delivery ref="refDeliveryProgress" class="my-2" id="delivery_progress"></your-delivery>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// import OrdersByType from '@/components/orders/OrdersByType.vue'
import YourDelivery from '@/components/delivery/YourDelivery.vue'
import StatisticsCardVertical from '@/components/statistics-card/StatisticsCardVertical.vue'

export default {
  components: {
    // OrdersByType,
    YourDelivery,
    StatisticsCardVertical,
  },
  data: () => ({
    totalCampaign: {
      active: 0,
      all: 0,
      close: 0,
    },
    totalPO: 0,
    loadingStatistic: null,
  }),
  methods: {
    getTotal() {
      this.totalCampaign = 0
      this.loadingStatistic = true

      this.$axios
        .get('campaign/total-campaign', { cacheConfig: true, })
        .then(res => {
          if (res.status) {
            var resData = res.data
            if (resData.status == 'success') {
              this.totalCampaign = resData.data
            } else {
              this.showAlert(resData.status, resData.message)
            }
          }
          this.loadingStatistic = false
        })
        // eslint-disable-next-line no-unused-vars
        .catch(error => {
          // console.log(error)
          this.showAlert('error', 'Internal Server Error')
          this.loadingStatistic = false
        })
    },
  },
  mounted() {
    this.$store.state.showBackButton = false
    document.title = 'Delivery'
    this.$store.state.pageTitle = document.title

    this.getTotal()
  },
}
</script>