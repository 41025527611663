<template>
  <div>
    <v-card :loading="loadingCard">
      <!-- <v-skeleton-loader
        v-bind="{
          boilerplate: true,
          elevation: 2,
        }"
        v-if="loadingCard"
        type="tab, table"
      >
      </v-skeleton-loader> -->
      <v-card-title>
        {{ title }}
        <v-spacer></v-spacer>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
      </v-card-title>
      <v-data-table
        :loading="loading"
        loading-text="Loading... Please wait"
        :headers="columns"
        :items="data"
        :search="search"
        dense
      >
        <template v-slot:item.delivery_note_no_="{ item }">
          <a href="javascript:;" @click="getDetail(item)">{{ item.delivery_note_no_ }}</a>
        </template>
        <template v-slot:item.loading_schedule="{ item }">{{ appDateFormat(item.loading_schedule) }}</template>
        <template v-slot:item.po_no_="{ item }" class="text-truncate">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on" class="d-inline-block text-truncate" style="max-width: 250px">
                {{ item.po_no_ }}
              </span>
            </template>
            <span>{{ item.po_no_ }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.contract_no_="{ item }" class="text-truncate">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on" class="d-inline-block text-truncate" style="max-width: 250px">
                {{ item.contract_no_ }}
              </span>
            </template>
            <span>{{ item.contract_no_ }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.customer_name="{ item }" class="text-truncate">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on" class="d-inline-block text-truncate" style="max-width: 250px">
                {{ item.customer_name }}
              </span>
            </template>
            <span>{{ item.customer_name }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.campaign_description="{ item }" class="text-truncate">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on" class="d-inline-block text-truncate" style="max-width: 250px">
                {{ item.campaign_description }}
              </span>
            </template>
            <span>{{ item.campaign_description }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog v-model="dialog">
      <v-card :loading="dialogCardLoading" flat tile>
        <v-card-title> Delivery Note </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="max-height:">
          <v-row>
            <v-col cols="12" md="6">
              <v-row>
                <v-col cols="12" md="6" class="py-1 font-weight-bold">Shipment No:</v-col>
                <v-col cols="12" md="6" class="py-1 text-muted">{{ dataDetail.header.delivery_note_no_ }}</v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6" class="py-1 font-weight-bold">Date</v-col>
                <v-col cols="12" md="6" class="py-1 text-muted">{{
                  appDateFormat(dataDetail.header.shipment_date)
                }}</v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6" class="py-1 font-weight-bold">SO No:</v-col>
                <v-col cols="12" md="6" class="py-1 text-muted">{{ dataDetail.header.order_no_ }}</v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6" class="py-1 font-weight-bold">Quote No:</v-col>
                <v-col cols="12" md="6" class="py-1 text-muted">{{ dataDetail.header.quote_no_ }}</v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6" class="py-1 font-weight-bold">PO No:</v-col>
                <v-col cols="12" md="6" class="py-1 text-muted">{{ dataDetail.header.po_no_ }}</v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6" class="py-1 font-weight-bold">Shipping Method:</v-col>
                <v-col cols="12" md="6" class="py-1 text-muted">{{ dataDetail.header.shipping_method_code }}</v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6" class="py-1 font-weight-bold">Car No:</v-col>
                <v-col cols="12" md="6" class="py-1 text-muted">{{ dataDetail.header.external_document_no_ }}</v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-sheet elevation="0" outlined height="100%" width="100%" class="pa-0">
                <v-tabs class="py-0" v-model="tab2">
                  <v-tab class="py-0">Project</v-tab>
                  <v-tab class="py-0">Customer</v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab2">
                  <v-tab-item class="pa-4">
                    <v-row>
                      <v-col cols="12" class="text-muted my-0 py-0">{{ dataDetail.header.campaign_no_ }}</v-col>
                      <v-col cols="12" class="text-muted my-0 py-0">{{ dataDetail.header.campaign_description }}</v-col>
                    </v-row>
                  </v-tab-item>
                  <v-tab-item class="pa-4">
                    <v-row>
                      <v-col cols="12" class="text-muted my-0 py-0">{{ dataDetail.header.customer_no_ }}</v-col>
                      <v-col cols="12" class="text-muted my-0 py-0">{{ dataDetail.header.customer_name }}</v-col>
                    </v-row>
                  </v-tab-item>
                </v-tabs-items>
              </v-sheet>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-data-table
          :loading="dialogCardLoading"
          loading-text="Loading... Please wait"
          :headers="dataDetail.line.columns"
          :items="dataDetail.line.data"
          :search="dataDetail.line.search"
          dense
        >
        </v-data-table>
        <v-divider></v-divider>
        <v-card-actions class="pt-1">
          <v-btn color="primary" @click="downloadPdf()" small text
            ><v-icon>mdi-download</v-icon> Download Document</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="dialog = false" small text>Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>


<script>
// eslint-disable-next-line no-unused-vars
import { mdiTruckFast } from '@mdi/js'

export default {
  components: {},
  data: () => ({
    tabl: null,
    tab2: null,
    title: 'Received By Customer',
    icon: mdiTruckFast,
    search: null,
    loading: null,
    columns: [
      { text: 'Order Type', value: 'order_type' },
      { text: 'No.', value: 'delivery_note_no_' },
      { text: 'Shipment Date', value: 'shipment_date' },
      { text: 'Customer', value: 'customer_name' },
      { text: 'Project', value: 'campaign_description' },
      { text: 'Contract No.', value: 'contract_no_' },
      { text: 'PO No.', value: 'po_no_' },
    ],
    data: [],
    dataDetail: {
      header: {},
      line: {
        search: null,
        data: [],
        columns: [
          { text: 'Item No.', value: 'item_no_' },
          { text: 'Description', value: 'item_description' },
          { text: 'Quantity', value: 'quantity' },
          { text: 'Unit', value: 'uom' },
          { text: 'Net Weight', value: 'net_weight' },
          { text: 'Total Weight', value: 'net_weight' },
        ],
      },
    },
    selectedData: {},
    loadingCard: null,
    dialog: null,
    dialogCardLoading: null,
  }),
  methods: {
    async getList() {
      try {
        this.loadingCard = true
        this.loading = true
        this.data = []
        await this.$axios
          .get('delivery/received-by-customer/list', {
            cacheConfig: true,
          })
          .then(res => {
            if (res.status) {
              var resData = res.data
              if (resData.status == 'success') {
                this.data = resData.data.results
              } else {
                this.showAlert(resData.status, resData.message)
              }
            }
            this.loading = false
            this.loadingCard = false
          })
          .catch(error => {
            this.showAlert('error', 'Internal Server Error : ' + error.toString())
            this.loading = false
            this.loadingCard = false
          })
      } catch (error) {
        this.data = []
        this.loading = false
        this.loadingCard = false
        this.showAlert('error', 'Internal server error : ' + error.toString())
      }
    },

    async getDetail(item) {
      this.dataDetail.header = {}
      this.dataDetail.line.data = []

      this.dialog = true
      this.dialogCardLoading = true

      var data = {
        params: {
          delivery_note_no_: item.delivery_note_no_,
        },
        cacheConfig: true,
      }

      await this.$axios
        .get('delivery/received-by-customer/detail', data)
        .then(res => {
          if (res.status) {
            var resData = res.data
            if (resData.status == 'success') {
              this.dataDetail.header = resData.data.header
              this.dataDetail.line.data = resData.data.line
            } else {
              this.showAlert(resData.status, resData.message)
            }
          }
          this.dialogCardLoading = false
        })
        .catch(error => {
          this.showAlert('error', 'Internal Server Error : ' + error.toString())
          this.dialog = false
          this.dialogCardLoading = false
        })
    },

    async downloadPdf() {
      let delivery_note_no_ = this.dataDetail.header.delivery_note_no_
      let params = {
        no_: delivery_note_no_,
      }
      this.$store.state.showLoadingDialog = true
      await this.$axios
        .get('documents/delivery', {
          params: params,
          responseType: 'blob',
          cacheConfig: true,
        })
        .then(response => {
          this.$store.state.showLoadingDialog = false
          console.log(response.data)
          if (response.data.type == 'application/json') {
            this.showAlert('warning', 'File not found.')
          } else if (response.data.type == 'application/octet-stream') {
            const file = new Blob([response.data], { type: 'application/pdf' })
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file)
            //Open the URL on new Window
            window.open(fileURL)
          } else {
            this.showAlert('warning', 'File not found.')
          }
        })
        .catch(error => {
          this.$store.state.showLoadingDialog = false
          console.log(error)
          this.showAlert('error', 'File not found.')
        })
    },
  },

  beforeMount() {},

  mounted() {
    this.getList()
  },
}
</script>

<style>
table.font-size-sm tbody td {
  font-size: 12px !important;
}
.lbl {
  color: #bdbdbd !important;
}
.vl {
  color: #616161 !important;
}
</style>