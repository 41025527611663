<template>
  <v-dialog v-model="dialog" scrollable max-width="1000px">
    <v-card outlined height="600">
      <v-card-title class="py-3">
        <v-row dense>
          <v-col cols="12" sm="6" class="d-flex justify-start my-1">
            <span style="margin-top: 5px">Delivery #{{ deliveryDetailData.header.do_no_ }}</span>
          </v-col>
          <v-col cols="12" sm="6" class="d-flex justify-end my-0">
            <v-btn icon @click="dialog = false" small rounded>
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>

      <v-divider class="my-1"></v-divider>

      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6">
            <v-row>
              <v-col cols="4" md="4" class="py-1 text-left">DO No. </v-col>
              <v-col cols="8" md="8" class="py-1 text-left">{{ deliveryDetailData.header.do_no_ }}</v-col>
            </v-row>
            <v-row>
              <v-col cols="4" md="4" class="py-1 text-left">Loading Schedule </v-col>
              <v-col cols="8" md="8" class="py-1 text-left">{{
                appDateFormat(deliveryDetailData.header.loading_schedule)
              }}</v-col>
            </v-row>
            <v-row>
              <v-col cols="4" md="4" class="py-1 text-left">Your PO No. </v-col>
              <v-col cols="8" md="8" class="py-1 text-left">{{ deliveryDetailData.header.po_no_ }}</v-col>
            </v-row>
            <v-row>
              <v-col cols="4" md="4" class="py-1 text-left">PO Date.</v-col>
              <v-col cols="8" md="8" class="py-1 text-left">{{
                appDateFormat(deliveryDetailData.header.po_date)
              }}</v-col>
            </v-row>
            <v-row>
              <v-col cols="4" md="4" class="py-1 text-left">Contract No. </v-col>
              <v-col cols="8" md="8" class="py-1 text-left">{{ deliveryDetailData.header.contract_no_ }}</v-col>
            </v-row>
            <v-row>
              <v-col cols="4" md="4" class="py-1 text-left">Contract Date </v-col>
              <v-col cols="8" md="8" class="py-1 text-left">{{ deliveryDetailData.header.contract_date }}</v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="6">
            <v-row>
              <v-col cols="4" class="py-1 text-left">Project Name </v-col>
              <v-col cols="8" class="py-1 text-left">{{ deliveryDetailData.header.campaign_description }}</v-col>
            </v-row>
            <v-row>
              <v-col cols="4" class="py-1 text-left">Project No. </v-col>
              <v-col cols="8" class="py-1 text-left">{{ deliveryDetailData.header.campaign_no_ }}</v-col>
            </v-row>
            <v-row>
              <v-col cols="4" class="py-1 text-left">Order Type </v-col>
              <v-col cols="8" class="py-1 text-left">{{ deliveryDetailData.header.order_type }}</v-col>
            </v-row>
            <v-row>
              <v-col cols="4" class="py-1 text-left">Due Date </v-col>
              <v-col cols="8" class="py-1 text-left">{{ appDateFormat(deliveryDetailData.header.due_date) }}</v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="pb-0 mb-0">
          <v-col cols="6" class="pb-0 mb-0"></v-col>
          <v-col cols="6" class="pb-0 mb-0">
            <v-text-field placeholder="Search" label="Search" v-model="search" class="pb-0 mb-0  float-right" style="max-width:200px" dense outlined hide-details="hide"></v-text-field>
          </v-col>
          <v-col cols="6" class="pb-0 mb-0"></v-col>
        </v-row>
        <v-row class="pt-0 mt-0 px-0">
          <v-col cols="12">
            <v-data-table
              :loading="datatable.loading"
              loading-text="Loading... Please wait"
              :headers="datatable.columns"
              :items="datatable.data"
              :search="search"
              dense
              class="mb-3 my-0"
            >
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider class="my-1"></v-divider>
      <v-card-actions class="pt-1">
        <v-btn color="primary" @click="downloadPdf()" small text><v-icon>mdi-download</v-icon> Download Document</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="dialog = false" small text>Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  components: {},
  data: () => ({
    search:null,
    dialog: null,
    deliveryDetailData: {
      header: {},
      detail: [],
    },
    datatable: {
      loading: null,
      loadingText: 'Loading... Please wait...',
      columns: [
        { text: 'Item No.', value: 'item_no_' },
        { text: 'Item Description', value: 'item_description' },
        { text: 'Quantity', value: 'quantity', align: 'end' },
        { text: 'UoM.', value: 'uom' },
      ],
      data: [],
    },
  }),
  methods: {
    getData(do_no_, source) {
      this.dialog = false
      this.$store.state.showLoadingDialog = true

      this.deliveryDetailData = {
        header: {},
        detail: [],
      }
      var params = {
        do_no_: do_no_,
        source: source,
      }
      this.$axios
        .get('delivery/detail', {
          params: params,
          cacheConfig: true,
        })
        .then(res => {
          if (res.status) {
            var resData = res.data
            if (resData.status == 'success') {
              this.deliveryDetailData.header = resData.data.header
              this.deliveryDetailData.detail = resData.data.detail

              this.datatable.data = resData.data.detail
            }
          } else {
            this.$awn.alert(resData.message)
          }
          this.$store.state.showLoadingDialog = false

          // console.log(this.deliveryDetailData)

          this.dialog = true
        })
        .catch(error => {
          if (error.response) {
            this.$awn.alert('Internal Server Error')
            // console.log(error.response.headers)
          } else {
            // console.log('Error', error.message)
          }
          this.$store.state.showLoadingsheet = false
        })
    },

    async downloadPdf() {
      let do_no_ = this.deliveryDetailData.header.do_no_
      let params = {
        no_: do_no_,
      }
      this.$store.state.showLoadingDialog = true
      await this.$axios
        .get('documents/delivery', {
          params: params,
          responseType: 'blob',
        })
        .then(response => {
          this.$store.state.showLoadingDialog = false
          // console.log(response.data)
          if (response.data.type == 'application/json') {
            this.showAlert('warning', 'File not found.')
          } else if (response.data.type == 'application/octet-stream') {
            const file = new Blob([response.data], { type: 'application/pdf' })
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file)
            //Open the URL on new Window
            window.open(fileURL)
          } else {
            this.showAlert('warning', 'File not found.')
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch(error => {
          this.$store.state.showLoadingDialog = false
          // console.log(error)
          this.showAlert('error', 'File not found.')
        })
    },
  },
}
</script>