<template>
  <div>
    <v-row>
      <v-col cols="12" lg="12">
        <v-card>
          <!-- <v-card-title>Invoice</v-card-title> -->
          <v-toolbar>
            <!-- <v-app-bar-nav-icon></v-app-bar-nav-icon> -->
            <v-toolbar-title>Delivery Progress</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon>
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </v-toolbar>

          <!-- <v-card-title>Order Confirmation</v-card-title> -->
          <v-tabs v-model="tab" class="tab-index">
            <v-tab v-for="(item, i) in tabs" :key="'TAB' + i">
              <v-icon class="mr-2">{{ item.icon }}</v-icon>
              {{ item.name }}
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item v-for="(item, i) in tabs" :key="'TAB_ITEM' + i">
              <component v-bind:is="item.component"></component>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>


<script>
// eslint-disable-next-line no-unused-vars
import { mdiDolly, mdiTruckFast, mdiTruckCheck } from '@mdi/js'
import DeliveryPreparation from '@/components/delivery/DeliveryPreparation.vue'
import OnDelivery from '@/components/delivery/OnDelivery.vue'
import ReceivedByCustomer from '@/components/delivery/ReceivedByCustomer.vue'

export default {
  components: {
    DeliveryPreparation,
    OnDelivery,
    ReceivedByCustomer,
  },
  data: () => ({
    search: '',
    selectedData: {},
    tabs: [
      {
        name: 'Delivery Preparation',
        icon: mdiDolly,
        component: 'DeliveryPreparation',
      },
      {
        name: 'On Delivery',
        icon: mdiTruckFast,
        component: 'OnDelivery',
      },
      {
        name: 'Received By Customer',
        icon: mdiTruckCheck,
        component: 'ReceivedByCustomer',
      },
    ],
    tab: {},
    loadingCard: null,
  }),
  methods: {},

  beforeMount() {},

  mounted() {},
}
</script>

<style>
table.font-size-sm tbody td {
  font-size: 12px !important;
}
.lbl {
  color: #bdbdbd !important;
}
.vl {
  color: #616161 !important;
}
</style>